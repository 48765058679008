<template>
  <div>
    <b-row>
      <b-col md="12">
        <export-button :filter="exportFilter"
                       url="/exclusion-system/report-exclusion/export"
                       :disabled="loading.overlay || loading.search"
                       file-title="Exclusions_Report"/>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">
            {{ $t("tabs.reportExclusion") }}
          </b-card-title>

          <b-row>

            <b-col>
              <b-form-group
                  label="init Date"
                  label-for="initDate"
              >
                <template #label>{{ $t('labels.datefrom')}}</template>
                <flat-pickr
                    v-model="initDate"
                    class="form-control"
                    :config="{
                    altInput: true,
                    clearable: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                />

              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="end Date"
                  label-for="endDate">
                <template #label>{{ $t('labels.dateto')}}</template>

                <flat-pickr
                    v-model="endDate"
                    class="form-control"
                    :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                />

              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Player Name" label-for="username">
                <template #label>{{ $t('labels.name_player') }}</template>
                <v-select
                    @option:selected="selectUser"
                    v-model="userSelected"
                    :options="users"
                    @search="onSearch"
                    :filterable="false"
                    :clearable="true"
                    :loading="loading.search"
                    label="userName"
                >
                  <template slot="no-options">
                    {{ $t("type_a_letter_to_start_the_search") }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.userName }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <div class="media pt-2">
                <div class="text-center">
                  <span>
                    {{ $t('total_excluded') }} <b-badge variant="primary">
                    {{ data.total_exclusions }}
                  </b-badge>
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-overlay :show="loading.overlay">
            <b-table-simple style="min-height: 300px"
                            hover
                            caption-top
                            small
                            responsive>

              <b-thead head-variant="light">
                <b-tr>
                  <b-th>ID</b-th>
                  <b-th>{{ $t('labels.userName') }}</b-th>
                  <b-th>{{ $t('labels.type') }}</b-th>
                  <b-th>{{ $t('init_date')}}</b-th>
                  <b-th>{{ $t('end_date')}}</b-th>
                  <b-th>{{ $t('motive')}}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>

                <b-tr
                    :variant="tr._rowVariant"
                    v-for="(tr, indexTr) in data.exclusions"
                    :key="indexTr"
                >
                  <b-td class="text-noWrap">{{ tr._id }}</b-td>
                  <b-td class="text-noWrap">{{ tr.username }}</b-td>
                  <b-td class="text-noWrap">{{ tr.type }}</b-td>
                  <b-td class="text-noWrap">{{ formatDate(tr.start_date) }}</b-td>
                  <b-td class="text-noWrap">{{ formatDate(tr.end_date) }}</b-td>
                  <b-td class="text-noWrap">{{ tr.reason }}</b-td>
                </b-tr>
                <b-tr v-if='data.exclusions.length < 1'>
                  <b-th colspan="5" class='text-center'>
                    <div class='m-2'>{{ $t('no_data') }}</div>
                  </b-th>
                </b-tr>
              </b-tbody>


            </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>

import {defineComponent, onUnmounted} from "@vue/composition-api";
import {
  BCard,
  BCardTitle,
  BCol,
  BFormGroup,
  BOverlay,
  BRow,
  BTable,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import {mapActions, mapState} from "vuex";
import userStoreModule from "@/views/apps/user/userStoreModule";
import store from "@/store";
import moment from "moment";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { axiosErrorHandle } from '@core/utils/errorHandler'
import {translatableText} from "@core/utils/utils";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";

export default defineComponent({
  name: "Exclusions.vue",
  components: {
    ExportButton,
    vSelect,
    BFormGroup,
    BTable, BTbody, BTd, BTr, BThead, BTh, BTableSimple, BOverlay, BCardTitle, BCard, BCol, BRow,
    flatPickr
  },

  data() {
    return {
      loading: {
        overlay: false,
        search: false
      },
      initDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD:23:59:59'),
      data: {
        exclusions: [],
        total_exclusions: 0
      },

      users: [],
      userSelected: null
    }
  },

  setup() {
      if (!store.hasModule('app-user'))
          store.registerModule('app-user', userStoreModule);

      onUnmounted(() => {
          if (store.hasModule('app-user')) store.unregisterModule('app-user')
      })
  },

  watch: {
    initDate: {
      handler() {
        if (this.initDate !== '' && this.endDate === '') {
          this.endDate = new Date()
        }

        if (this.initDate === '') {
          this.endDate = ''
        }

        this.getData()
      }
    },
    endDate: {
      handler() {
        this.getData()
      }
    },
    userSelected(value) {
      if (!value) {
        this.getData()
      }
    }
  },


  computed: {
    ...mapState("whitelabelCurrencyNabvar", ["whitelabel", "currency"]),
    exportFilter(){
      return {
        id: this.userSelected?._id,
        currency: this.currency,
        whitelabel: this.whitelabel._id,
        datein: this.initDate,
        dateout: this.endDate,
        getType : this.userSelected?._id ? null : "All"
      }
    }
  },

  methods: {
    yearDropdownPlugin,
    ...mapActions("exclusion", ["getExclusions"]),

    getData() {
      this.loading.overlay = true

      const filter = {
        id: this.userSelected?._id,
        currency: this.currency,
        whitelabel: this.whitelabel._id,
        datein: this.initDate,
        dateout: this.endDate,
        getType : this.userSelected?._id ? null : "All"
      }


      this.getExclusions(filter).then(r => {
        this.data = r.data

      })
        .catch(async (error) => {
          this.data.exclusions = [] // <--reset data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              icon: "AlertTriangleIcon",
              text: await translatableText({text: axiosErrorHandle(error)}),
              variant: "danger",
            },
          });
        })
        .finally(() => {
        this.loading.overlay = false
      })
    },

    selectUser() {
      this.getData()
    },

    onSearch(username) {
      if (username.length) {
        this.loading.search = true

        if (this.timeOut) clearTimeout(this.timeOut);

        const whitelabelId = this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const queryParams = { username: username.trim(), whitelabel: whitelabelId };

        this.timeOut = setTimeout(() => {
          store.dispatch("app-user/fetchUsersByName", queryParams).then((r) => {
            this.users = r.data;
            this.loading.search = false

          }).catch(() => {
            this.loading.search = false
          })
        }, 500);
      }
    },

    formatDate(value) {
      return value ? moment(value).format('DD/MM/YYYY hh:mm a') : '-'
    }

  },
  
  mounted() {
   this.getData() 
  }

})
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}

.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>

